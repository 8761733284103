(function () {
  class Payment extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY ID
      this.ui.formNewPay = "#FormNewPay";
      this.ui.customerID = "#customer_id";
      this.ui.loanID = "#loan_id";
      this.ui.nationality = "#nationality";
      this.ui.qCustomerIDNumber = "#q_customer_id_number_eq";

      // BY Class
      this.ui.jsLoanDetails = ".js-loan-details";
    };

    loadComponents() {
      const operation = this;

      operation.configure();
      operation.needFormValidation();
      operation.changeNationality();
      operation.loadSubmit();
    };

    loadSubmit() {
      const operation = this;

      $("form#FormNewPay").bind("ajax:beforeSend", function (event, data, status, xhr) {
        $(operation.ui.jsLoanDetails).removeClass("d-block").addClass("d-none");
        $(".js-loading").removeClass('d-none');
        return true
      }).bind("ajax:complete", function (event, data, status, xhr) {
        $(".js-loading").addClass('d-none');

      }).bind("ajax:success", function (event, data, status, xhr) {
        $(".js-loading").addClass('d-none');
      });
    };

    showConfirmation(id) {
      const operation = this;

      $.ajax({
        method: 'GET',
        url: Routes.payment_path({ id: id, format: "js" }),
        dataType: 'script'
      }).done(function () {
      }).fail(function () {
      });
    };

    changeNationality() {
      const operation = this;
      $(operation.ui.nationality).on('change', function () {
        var selected = $(this).find('option:selected').val();

        if (selected == "national") {
          $(operation.ui.qCustomerIDNumber).addClass("formatDNI");
        } else {
          $(operation.ui.qCustomerIDNumber).removeClass("formatDNI");
          $(operation.ui.qCustomerIDNumber).rules("remove", "formatDNI");
        };

        if ($(operation.ui.qCustomerIDNumber).val() != "") {
          $(operation.ui.qCustomerIDNumber).valid();
        };
      });
    };
  };


  window.Payment = Payment;
})();
