(function () {
  class PaymentAgreement extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY ID
      this.ui.paymentAgreementRadio = 'input[name="payment_agreement[type]"]';
      this.ui.reducedType = "#reduced_type";
      this.ui.distributedType = "#distributed_type";
      this.ui.paymentAgreementPaymentDate = "#payment_agreement_payment_date";
      this.ui.paymentAgreementQuantityLetter = "#payment_agreement_quantity_letter";

      // BY CLASS
      this.ui.jsSimulateBtn = '.js-simulate';
      this.ui.jsCreatePaymentAgreementBtn = ".js-create-payment-agreement-btn"
      this.ui.jsCreatePaymentAgreements = "form.js-create-payment-agreements";
    };

    loadComponents() {
      this.configure();
      this.needFormValidation();
      this.changePaymentAgreementType();
    };

    changePaymentAgreementType() {
      const operation = this;

      $(operation.ui.paymentAgreementRadio).on('change', function () {
        var maxLetter = $(".js-max-letter").data('max-letter');
        var selected = $(operation.ui.paymentAgreementRadio + ':checked').val();
        if (selected == 'reduced_type') {
          $(operation.ui.paymentAgreementQuantityLetter).removeAttr('max');
        } else {
          $(operation.ui.paymentAgreementQuantityLetter).attr('max', maxLetter);
        }

        if ($(operation.ui.paymentAgreementQuantityLetter).val() != "") {
          $(operation.ui.paymentAgreementQuantityLetter).valid();
        };
      });
    };

    sendSimulateForm(url) {
      const operation = this;

      $(operation.ui.jsSimulateBtn).on('click', function (evt) {
        var form = $(operation.ui.jsFormValidation);

        evt.preventDefault();
        evt.stopImmediatePropagation();
        evt.stopPropagation();

        form.valid();

        if (!form.valid()) {
          return false;
        };

        var showUntil = moment().add(1, 'seconds');
        var dialog = bootbox.dialog({
          centerVertical: true,
          closeButton: false,
          message: '<p class="text-center"><i class="fas fa-sync fa-spin fa-4x"></i></p>' +
            '<h5 class="text-center">Procesando...</h5>'
        });

        $.ajax({
          method: "POST",
          url: url,
          data: form.serialize()
        }).done(function () {
          console.log("Done!");
        }).fail(function () {
          console.log("error");
        });

        var show = showUntil - moment()

        if (show < 0) {
          dialog.modal('hide');
        } else {
          setTimeout(function () { dialog.modal('hide'); }, show);
        };
      });
    };

    sendCreatePaymentAgreement() {
      const operation = this;

      $(operation.ui.jsCreatePaymentAgreementBtn).on('click', function (evt) {
        console.log("ENTRO")
        evt.preventDefault();
        evt.stopImmediatePropagation();
        evt.stopPropagation();

        var dialog = bootbox.confirm({
          message: "Esta seguro que deseas generar el Arreglo de Pago? <br> Esta acción no se puede reversar.",
          buttons: {
            confirm: {
              label: 'Sí',
              className: 'btn-success'
            },
            cancel: {
              label: 'No',
              className: 'btn-danger'
            }
          },
          callback: function (result) {
            if (result) {
              $(operation.ui.jsCreatePaymentAgreements).submit();
            } else {
              dialog.modal('hide');
              return false;
            }
          }
        });

        return false;
      });
    };
  };

  window.PaymentAgreement = PaymentAgreement;
})();
