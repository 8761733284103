(function () {
  class CollectionGoal extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY ID
      this.ui.startDate = "#q_start_date_gt";

      // BY Class
      this.ui.jsSubmit = ".js-submit";
      this.ui.jsLoading = ".js-loading";
      this.ui.jsCardReport = ".js-card-report";
      this.ui.jsCollectionGoalsForm = 'form.js-collection-goals-form';
    };

    loadComponents() {
      this.configure();
      this.needFormValidation();
      this.loadCollectionGoalsForm();
      this.loadTooltips();
    };

    loadCollectionGoalsForm() {
      const operation = this;

      $(operation.ui.jsCollectionGoalsForm).on('click', function () {
        if (!$(operation.ui.jsCollectionGoalsForm).valid()) {
          $(".js-ajax-indicator").removeClass('d-block').addClass('d-none');
        }
      });

      $(operation.ui.jsCollectionGoalsForm).bind("ajax:beforeSend", function (event, data, status, xhr) {
        var divLoading = $(operation.ui.jsLoading);
        var divCardReport = $(operation.ui.jsCardReport);

        divCardReport.each(function (idx, element) {
          $(element).removeClass("d-block").addClass("d-none");
        });

        divLoading.each(function (idx, element) {
          $(element).removeClass("d-none").addClass("d-block");
          $(element).html('<p class="text-center"><i class="fas fa-sync fa-spin fa-4x"></i></p><h5 class="text-center">Cargando Reporte...</h5>');
        });
      }).bind("ajax:complete", function (event, data, status, xhr) {
        $(".js-ajax-indicator").removeClass('d-block').addClass('d-none');
      }).bind("ajax:success", function (event, data, status, xhr) {
        $(".js-ajax-indicator").removeClass('d-block').addClass('d-none');
      });
    };
  };

  window.CollectionGoal = CollectionGoal;
})();
