(function () {
  class Customer extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY ID
      this.ui.customerIdNumber = "#customer_id_number";
      this.ui.customerNationality = "#customer_nationality";
      this.ui.customerDetailsIdEq = '#q_id_eq';
      this.ui.customerBirthDate = '#customer_birth_date'
    };

    loadComponents() {
      this.configure();
      this.needFormValidation();
      this.changeNationality();
      this.loadCustomFileInput();
      this.loadValidateBirthDate();
    };

    changeNationality() {
      const operation = this;
      $(operation.ui.customerNationality).on('change', function () {
        var selected = $(this).find('option:selected').val();

        if (selected == "national") {
          $(operation.ui.customerIdNumber).addClass("formatDNI");
          $(operation.ui.customerIdNumber).rules('add', { formatDNI: true });
        } else {
          $(operation.ui.customerIdNumber).removeClass("formatDNI");
          $(operation.ui.customerIdNumber).rules("remove", "formatDNI");
        }

        if ($(operation.ui.customerIdNumber).val() != "") {
          $(operation.ui.customerIdNumber).valid();
        };
      });
    };

    changeCustomerDetails() {
      const operation = this;

      $(operation.ui.customerDetailsIdEq).on('change', function () {
        var selected = $(this).find('option:selected').val();

        bootbox.dialog({
          centerVertical: true,
          closeButton: false,
          message: i18n.t('bootbox.processing_html')
        });

        $.ajax({
          method: 'GET',
          url: Routes.customer_detail_path({ id: selected, format: "js" }),
          dataType: 'script'
        }).done(function () {
          operation.hideBootBox();
        }).fail(function () {
          operation.hideBootBox();
        }).always(function () {
          operation.hideBootBox();
        });
      });
    };
  };

  window.Customer = Customer;
})();
