(function () {
  class CustomerConsent extends Loan {
    constructor() {
      super()
    };

    configure() {
      // BY IDs
      this.ui.companyID = "#loan_company_id";
      this.ui.personID = "#customer_consent_person_id";
      this.ui.loanCustomerNationality = "#customer_consent_customer_attributes_nationality";
      this.ui.customerId = "#customer_consent_customer_attributes_id";
      this.ui.customerIdNumber = "#customer_consent_customer_attributes_id_number";
      this.ui.customerIdNumberConfirmation = "#customer_consent_customer_attributes_id_number_confirmation";
      this.ui.customerFirstName = "#loan_customer_attributes_first_name";
      this.ui.customerSecondName = "#loan_customer_attributes_second_name";
      this.ui.customerFirstLastName = "#loan_customer_attributes_first_last_name";
      this.ui.customerSecondLastName = "#customer_consent_customer_attributes_second_last_name";
      this.ui.customerMarriedSurname = "#customer_consent_customer_attributes_married_surname";
      this.ui.customerBirthDate = "#customer_consent_customer_attributes_birth_date";
      this.ui.customerGender = "#customer_consent_customer_attributes_gender";
      this.ui.customerCellphone = "#customer_consent_cellphone_number";
      this.ui.customerEmail = "#customer_consent_email";
      this.ui.customerPhontoID = "#customer_consent_customer_attributes_id_photo";
      this.ui.customerPhotoFront = "#customer_consent_id_photo_front";
      this.ui.customerPhotoBack = "#customer_consent_id_photo_back";
      this.ui.customerPhotoWithUser = "#customer_consent_id_photo_with_user";
      this.ui.clearSignature = "#sig-clearBtn";
      this.ui.backPhoto = "#back-photo";
      this.ui.previewConsent = "#preview-consent";
      this.ui.submitBtn = "#submitBtn";
      this.ui.signature = "#sig-canvas";
      this.ui.signatureMessage = "#sig-message"

      // By Classes
      this.ui.jsFrontPhonto = ".js-front-photo";
      this.ui.jsBackPhoto = ".js-back-photo";
      this.ui.jsCustomerPhoto = ".js-customer-photo";
      this.ui.jsValidateDNI = ".js-validate-dni";
      this.ui.jsSearchCustomer = ".js-search-customer";
    };

    loadComponents() {
      const operation = this;

      operation.configure();
      operation.loadDataCompanyID();
      operation.onlyLetter();
      operation.onlyNumber();
      operation.textUpperCase();
      operation.needFormValidation();
      operation.maskedNumber();
      operation.validateDNI();
      operation.changeLabelOnNationality();
      operation.loadValidateBirthDate();
      //operation.loadCustomFileInput();
      operation.loadSearchCustomer();
      //operation.convertImgToBase64(operation.ui.customerPhontoID, operation.ui.customerPhotoFront);
      //operation.convertImgToBase64(operation.ui.backPhoto, operation.ui.customerPhotoBack);
      //operation.loadPreviewConsent();
      operation.loadSendConsent();

      $(operation.ui.customerIdNumberConfirmation).rules('add', {
        equalTo: operation.ui.customerIdNumber
      });

      $(operation.ui.customerCellphone).rules("add", {
        validateCellphone: { loan_id: "true" }
      });
    };

    validSignature() {
      const operation = this;
      var canvas = $(operation.ui.signature)[0];
      var context = canvas.getContext("2d");
      var imageData = context.getImageData(0, 0, canvas.width, canvas.height).data;

      for (var i = 0; i < imageData.length; i += 4) {
        if (imageData[i + 3] !== 0) {
          $(operation.ui.signatureMessage).text('');
          return true;
        }
      }
      $(operation.ui.signatureMessage).text('Este campo es obligatorio.');
      return false;
    }

    loadSearchCustomer() {
      const operation = this;
      $(operation.ui.jsSearchCustomer).on('blur', function () {
        if ($(operation.ui.customerIdNumber).valid() && $(operation.ui.customerIdNumberConfirmation).valid()) {
          var dni = this.value;

          bootbox.dialog({
            centerVertical: true,
            closeButton: false,
            message: i18n.t('bootbox.searching_html')
          });

          operation.resetFillForm();

          $.ajax({
            method: 'GET',
            url: Routes.search_customer_credit_infos_path(),
            data: { id_number: dni, nationality: $(operation.ui.loanCustomerNationality).find('option:selected').val() }
          }).done(function () {
            operation.hideBootBox();
          }).fail(function () {
            operation.hideBootBox();
          }).always(function () {
            operation.hideBootBox();
          });
        }
      });
    };

    resetFillForm() {
      const operation = this;
      $(operation.ui.customerId).val("");
      $(operation.ui.customerFirstName).val("");
      $(operation.ui.customerSecondName).val("");
      $(operation.ui.customerFirstLastName).val("");
      $(operation.ui.customerSecondLastName).val("");
      $(operation.ui.customerMarriedSurname).val("");
      $(operation.ui.customerBirthDate).val("");
      $(operation.ui.customerGender).val("");
      $(operation.ui.customerCellphone).val("");
      $(operation.ui.customerEmail).val("");
      $(operation.ui.customerPhotoFront).val("");
      $(operation.ui.customerPhotoBack).val("");
      operation.clearLoadFileInput();
      $(operation.ui.clearSignature).click();
    };

    fillForm(customer) {
      const operation = this;
      $(operation.ui.personID).val(customer['person_id']);
      $(operation.ui.customerId).val(customer['id']);
      $(operation.ui.customerFirstName).val(customer['first_name']);
      $(operation.ui.customerSecondName).val(customer['second_name']);
      $(operation.ui.customerFirstLastName).val(customer['first_last_name']);
      $(operation.ui.customerSecondLastName).val(customer['second_last_name']);
      $(operation.ui.customerBirthDate).val(customer['birth_date']);
      $(operation.ui.customerGender).val(customer['gender']);
      $(operation.ui.customerCellphone).val(customer['cellphone_number']);
      $(operation.ui.customerEmail).val(customer['email']);
    };

    loadPreviewConsent() {
      const operation = this;
      $(operation.ui.previewConsent).on('click', function () {
        if (!$(operation.ui.jsFormValidation).valid()) {
          return false;
        };

        if (!operation.validSignature()) {
          return false;
        };

        var formData = new FormData($(".js-need-validation")[0]);
        bootbox.dialog({
          centerVertical: true,
          closeButton: false,
          message: i18n.t('bootbox.processing_html')
        });

        $.ajax({
          method: 'POST',
          url: Routes.preview_customer_consents_path(),
          processData: false,
          contentType: false,
          data: formData
        }).done(function (response) {
          operation.hideBootBox();
        }).fail(function () {
          operation.hideBootBox();
        });
      });
    };

    loadSendConsent() {
      const operation = this;
      $("form#FormConsent").bind("ajax:beforeSend", function (event, data, status, xhr) {
        //if (!operation.validSignature()) {
        //  event.preventDefault();
        //  return;
        //};

        bootbox.dialog({
          centerVertical: true,
          closeButton: false,
          message: i18n.t('bootbox.processing_html')
        });
      }).bind("ajax:complete", function (event, data, status, xhr) {
        $('.bootbox.modal').modal('hide');
      }).bind("ajax:success", function (event, data, status, xhr) {
        $('.bootbox.modal').modal('hide');
      });
    };
  };

  window.CustomerConsent = CustomerConsent;
})();


