(function () {
  class DeviceManager extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY ID
      this.ui.jsDeviceManagerLink = ".js-device-manager-link";
    };

    loadConfirmationModal() {
      const operation = this;

      $(operation.ui.jsDeviceManagerLink).on('click', function () {
        var url = $(this).data('url');
        var message = $(this).data('message');

        Swal.fire({
          title: message,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#69BE5A',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            $.ajax({
              method: "GET",
              url: url,
            }).done(function () {

            }).fail(function () {
              console.log("error");
            });
          }
        })
      });
    };
  };

  window.DeviceManager = DeviceManager;
})();
