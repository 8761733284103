(function () {
  class CalculatorLoanDates {
    constructor(startDate) {
      this.dates = [];
      this.momentDate = moment(startDate);
    }

    determineDays() {
      const firstDay = this.momentDate.date();
      let secondDay = (firstDay === 16) ? 31 : this.momentDate.clone().add(15, 'days').date();
      const nextDate = this.momentDate.clone().add(14, 'days');

      if ((firstDay >= 13 && firstDay <= 16) &&
        (secondDay <= 3 || secondDay === 31) &&
        (nextDate.month() + 1 === 2 || this.momentDate.format('MM-DD') === '02-15')) {
        secondDay = 30;
      }

      return [firstDay, secondDay];
    }

    calculateNextDate(firstDay, secondDay, lastDate) {
      const monthsWith30Days = [2, 4, 6, 9, 11];
      const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
      let lastPaymentDate = moment(lastDate.at(-1));
      let nextDate = lastPaymentDate.clone().add(15, 'days');

      if (monthsWith30Days.includes(lastPaymentDate.month() + 1) && lastPaymentDate.date() <= 16) {
        nextDate = lastPaymentDate;
      }

      if ([1, 16].includes(firstDay) && [1, 16].includes(secondDay) &&
        ((monthsWith31Days.includes(nextDate.month() + 1) && nextDate.date() === 31) ||
          (monthsWith30Days.includes(nextDate.month() + 1) && nextDate.date() === 16))) {
        nextDate.add(1, 'month');
      }

      return nextDate;
    }

    getCurrentDay(maxLetterId, firstDay, secondDay) {
      return (maxLetterId + 1) % 2 === 0 ? secondDay : firstDay;
    }

    setInstallmentDay(nextDate, day) {
      try {
        const installmentDate = moment({ date: day, month: nextDate.month(), year: nextDate.year() });
        if (installmentDate.isValid()) {
          return nextDate.date(day);
        } else {
          return nextDate.endOf('month');
        }
      } catch (e) {
        return nextDate.endOf('month');
      }
    }

    calculateBiweekly(maxLetterId = 1) {
      this.dates.push(this.momentDate.format());
      const [firstDay, secondDay] = this.determineDays();

      for (let idx = 1; idx < maxLetterId; idx++) {
        let nextDate = this.calculateNextDate(firstDay, secondDay, this.dates);
        const currentDay = this.getCurrentDay(idx, firstDay, secondDay);
        nextDate = this.setInstallmentDay(nextDate, currentDay);
        this.dates.push(nextDate.format());
      }

      return this.dates.at(-1);
    }
  };

  window.CalculatorLoanDates = CalculatorLoanDates;
})();
