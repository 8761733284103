(function () {
  class Sale extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY ID
      this.ui.customerID = "#sale_customer_id";
      this.ui.loanID = "#sale_loan_id";
      this.ui.saleProductsProductID = "#sale_sale_products_attributes_0_product_id";
      this.ui.saleCollectionAmount = "#sale_collections_attributes_0_amount";

      // BY Class
      this.ui.jsLoanDetails = ".js-loan-details";
      this.ui.jsBalanceLetter = ".js-balance-letter";
      this.ui.jsPdfPreview = ".js-pdf-preview";
    };

    loadComponents() {
      const operation = this;

      operation.configure();
      operation.needFormValidation();
      operation.loadSelect2Cascade(operation.ui.customerID, operation.ui.loanID);
      operation.loadLoanSelect();
      operation.loadProductSelect();
      operation.maskedNumber();
      $(operation.ui.saleProductsProductID).change();
    };

    loadLoanSelect() {
      const operation = this;

      $(operation.ui.customerID).on('change', function () {
        $(operation.ui.jsLoanDetails).addClass("text-center");
        $(operation.ui.jsLoanDetails).html("<h4>Visualización del Detalle del Préstamo.</h4>");
      });

      $(operation.ui.loanID).on('select2:select', function () {
        let selected = $(this).find('option:selected').val();

        $(operation.ui.jsLoanDetails).removeClass("d-block").addClass("d-none");
        $(operation.ui.jsLoading).removeClass("d-none").addClass("d-block");

        findLoans(selected);
      });

      $(operation.ui.loanID).on('select2:unselect', function () {
        let selected = $(this).find('option:selected').val();

        $(operation.ui.jsLoanDetails).removeClass("d-block").addClass("d-none");
        $(operation.ui.jsLoading).removeClass("d-none").addClass("d-block");

        findLoans(selected);
      });

      $(operation.ui.loanID).on('select2:clear', function () {
        let selected = $(this).find('option:selected').val();

        $(operation.ui.jsLoanDetails).removeClass("d-block").addClass("d-none");
        $(operation.ui.jsLoading).removeClass("d-none").addClass("d-block");

        findLoans(selected);
      });
    };

    loadProductSelect() {
      const operation = this;

      $(operation.ui.saleProductsProductID).on('change', function () {
        var data = $(this).find('option:selected').data('price');
        var price = parseFloat(data).format();
        $(operation.ui.saleCollectionAmount).val(price);
      });
    };

    loanConfirmBalanceLetterGenerateLink() {
      const operation = this;

      $(operation.ui.jsBalanceLetter).on('click', function () {
        var url = $(this).data('url');
        var customer = $(this).data('customer');
        var loan_id = $(this).data('loan-id');

        bootbox.confirm({
          title: "Necesita Confirmación",
          message: "Estas seguro que deseas generar <b>Carta de Saldo</b> para el Cliente: " + customer +
            '<br>' +
            "N# Préstamo: <b>" + loan_id + "</b>",
          buttons: {
            confirm: {
              label: "Confirmar",
              className: 'btn-success'
            },
            cancel: {
              label: "Cancelar",
              className: 'btn-cancel'
            }
          },
          callback: function (result) {
            if (result) {
              $.ajax({
                method: "POST",
                url: url,
                dataType: 'script',
              }).done(function () {

              }).fail(function () {
                console.log("error");
              });
            }
          }
        });
      });
    };
  };

  var findLoans = function (selected) {
    var selected = selected || -1;

    $(".js-pdf-preview").attr("href", "#").addClass("d-none");

    $.ajax({
      type: "GET",
      url: `/loans/${selected}`,
      dataType: 'script',
    }).done(function (data) {
      var url = `/js_show_pdf_preview/${selected}`
      $(".js-pdf-preview").attr("href", url).removeClass("d-none");
      console.log("Done.")
    })
  };

  window.Sale = Sale;
})();
