(function () {
  class Notification extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY Class
      this.ui.jsNotificationLink = ".js-notification-link";
      this.ui.jsChkSubmit = ".js-chk-submit";

      // BY ID
      this.ui.chkUserNotification = "#ChkUserNotification";
    };

    loadComponents() {
      this.configure();
      this.formShowNotificationLists();
      this.loadNotificationLink();
    };

    formShowNotificationLists() {
      const operation = this;

      $(operation.ui.chkUserNotification).on('click', function () {
        $(operation.ui.jsChkSubmit).trigger('click');
      });

      $('.dropdown-menu').on("click.bs.dropdown", function (e) {
        e.stopPropagation();
        //e.preventDefault();
      });
    };

    loadNotificationLink() {
      const operation = this;

      $(operation.ui.jsNotificationLink).on('click', function () {
        var url = $(this).data('url');

        $.ajax({
          method: "GET",
          url: url,
        }).done(function () {
        }).fail(function () {
        });
      });
    };
  };

  window.Notification = Notification;
})();
